import React, { useState } from 'react';
import { Box,    Typography } from '@mui/material';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './cropimage.scss';
import CustomButton from '../Button/CustomButton';

const Cropimage = ({
  file,
  localesData,
  getCropData,
  handleCropclose,
}) => {
  const [cropper, setCropper] = useState();
  const [crop, setCrop] = useState(false);


  return (
    <Box className='crop-image'>
      <Box className='crop-details'>
        <Typography variant='h6' className='crop-title-text'>
          {localesData?.POSITION_IMAGE}
        </Typography>
        <Typography variant='h5' className='crop-title-desc'>
          {localesData?.POSITION_IMAGE_DESC}
        </Typography>
      </Box>
      <Box className={crop ? 'cropped-image' : 'without-crop'}>
        <Cropper
          // ref={cropperRef}
          style={{ height: 300, maxWidth: '100%' }}
          // zoomable={false}
          aspectRatio={1}
          initialAspectRatio={1}
          // src={crop ? cropData : file?.preview}
          src={file}
          viewMode={1}
          // minCropBoxHeight={10}
          // minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          className={'profile-cropper'}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
          zoomOnWheel={false}
          dragMode='move'
          cropBoxMovable={false}
          cropBoxResizable={false}
        />
      </Box>
      {/* <Box className='crop-image-top'>
        <Box className='zoom-plus-minus'>
          <Box className='icon-btn'>
            <IconButton
              disabled={zoom > 0 ? false : true}
              className={zoom > 0 ? '' : 'disabled-btn'}
              onClick={() => zoom > 0 && zoomOut()}
            >
              <Remove />
            </IconButton>
          </Box>
          <Box className='zoom-bar'>
            <input
              type='range'
              min={0}
              max={1}
              step={0.1}
              value={zoom}
              // min="0"
              // max="1"
              // step="0.1"
              defaultValue={0}
              onChange={(e) => {
                customZoom(e.target.value);
              }}
            />
          </Box>
          <Box className='icon-btn'>
            <IconButton
              disabled={zoom < 1 ? false : true}
              className={zoom < 1 ? '' : 'disabled-btn'}
              onClick={() => zoom < 1 && zoomIn()}
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
      </Box> */}
      {/* <Divider className='divider' /> */}
      <Box className='crop-save-btn'>
        <Box className='all-block-wrap' textAlign='end'>
          <CustomButton
            variant='contained'
            title={localesData?.NEXT}
            background='#F54D71'
            fontWeight='600'
            onClick={() =>  getCropData(cropper)}
          />
        </Box>
      </Box>
    
    </Box>
  );
};

export default Cropimage;
